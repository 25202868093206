import React from "react";
import ReactDOM from "react-dom/client";
//import { hydrateRoot } from 'react-dom/client';
import { Auth0Provider } from "@auth0/auth0-react";
import App from "./App";
import "./styles/index.scss";

// Redux
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from "./reducers";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    immutableCheck: false,
  }),
})

const root = ReactDOM.createRoot(document.getElementById("root"));
//const root = hydrateRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* g-nicolas.eu */}
      <Auth0Provider
        domain="dev-gpgoqxywhk2alyb0.us.auth0.com"
        clientId="Q4elF87nK5C3kLak788XrsgpTxgHh3zX"
        redirectUri={window.location.origin}
      >
        <App />
      </Auth0Provider>
    </Provider>
  </React.StrictMode>
);
