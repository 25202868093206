import { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DataTable from "react-data-table-component";
import { tabChampLib4_2 } from "./Tableaux";
import { customStyles } from "./StylesDataTable4";
import { dateJJMMAAAA, isArrGag, isArrPla, isEmpty, heuCrs } from "./Utils";
import { getPa2sByChe } from "../actions/pac.actions";

const Cr4Compo = ({ cr4Exp }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [itemsCr4, setItemsCr4] = useState([]);
  const dispatch = useDispatch();
  const cr4 = useSelector((state) => state.pacReducer);

  useEffect(() => {
    if (isLoading) {
      dispatch(getPa2sByChe(cr4Exp.data.pa2_che_id));
      
     /*  let lastUpdate = Date.now();
      setTimeout(() => {
        const diff = Date.now() - lastUpdate;
        if (diff > 1000) { */
          setItemsCr4(cr4);
          !isEmpty(itemsCr4) && setIsLoading(false);
      //  }
      // }, 1000);
    }
  }, [cr4, cr4Exp.data.pa2_che_id, dispatch, isLoading, itemsCr4]);

  //Chargement tableaux
  const nameChamp = useMemo(() => tabChampLib4_2("nameChamp"), []);
  const libChampCourt = useMemo(() => tabChampLib4_2("libChampCourt"), []);
  const widChamp = useMemo(() => tabChampLib4_2("widChamp"), []);
  const tabSortable = useMemo(
    () => Array.from(nameChamp, () => true),
    [nameChamp]
  );

  const columns = Array.from({ length: nameChamp.length }, (v, i) => {
    return {
      name: libChampCourt[i],
      selector: (row) => row[nameChamp[i]],

      cell: (row) => {
        if (
          nameChamp[i] === "cr2_dtm" ||
          nameChamp[i] === "hyp_nom" ||
          nameChamp[i] === "pa2_dis" ||
          nameChamp[i] === "pa2_fav" ||
          nameChamp[i] === "pa2_fa4" ||
          nameChamp[i] === "joc_nom" ||
          nameChamp[i] === "ent_nom" ||
          nameChamp[i] === "ra2_s1g" ||
          nameChamp[i] === "ra2_pla"
        ) {
          if (nameChamp[i] === "cr2_dtm") {
            return dateJJMMAAAA(row.cr2_dtm);
          } else {
            if (nameChamp[i] === "hyp_nom") {
              return <p>{row.hyp_nom.toLowerCase().substr(0, 22)}</p>;
            } else {
              if (nameChamp[i] === "joc_nom") {
                return <p>{row.joc_nom.toLowerCase().substr(0, 22)}</p>;
              } else {
                if (nameChamp[i] === "ent_nom") {
                  return <p>{row.ent_nom.toLowerCase().substr(0, 22)}</p>;
                } else {
                  if (nameChamp[i] === "pa2_dis") {
                    return (
                      <p
                        style={{
                          color:
                            row.pa2_dis !== row.cr2_di2 &&
                            row.cr2_cat === "a" &&
                            row.pa2_cot !== 999.9
                              ? "#C70039"
                              : "black",
                        }}
                      >
                        {row.pa2_dis}
                      </p>
                    );
                  } else {
                    if (nameChamp[i] === "pa2_fav") {
                      return (
                        <p>
                          {row.pa2_fav}/{row.cr2_nbp}
                        </p>
                      );
                    } else {
                      if (nameChamp[i] === "pa2_fa4") {
                        return (
                          <p>
                            {row.pa2_fa4}/{row.cr2_nbp}
                          </p>
                        );
                    } else {
                      if (nameChamp[i] === "ra2_s1g") {
                        return isArrGag(row.pa2_arr, row.ra2_s1g);
                      } else {
                        if (nameChamp[i] === "ra2_pla") {
                          return isArrPla(
                            row.pa2_arr,
                            row.ra2_s1p,
                            row.ra2_s2p,
                            row.ra2_s3p
                          );
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          }
        } else {
          return row[nameChamp[i]];
        }
      },

      sortable: tabSortable[i],
      width: widChamp[i],
    };
  });

  const conditionalRowStyles = [
    {
      when: (row) => row.pa2_cot === 999.9,
      style: {
        backgroundColor: "#ff7b77",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: "Lignes par page",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Tous",
    //paginationPerPage: 20,
    // paginationRowsPerPageOptions: [20, 30, 40, 50]
  };

  const fixedHeader = {
    fixedHeader: true,
    fixedHeaderScrollHeight: "100px",
  };

  return (
    !isEmpty(itemsCr4) &&(
      <>
        <DataTable
          title={
            !isEmpty(cr4[0]) &&
            "Historique pour le cheval: " +
              cr4Exp.data.che_nom +
              " -Réunion-Course: " +
              cr4Exp.data.pa2_drc.substr(6, 5) +
              " -Numéro: " +
              cr4Exp.data.pa2_num +
              " à " +
              heuCrs(cr4Exp.data.cr2_heu.toString())
          }
          data={itemsCr4}
          columns={columns}
          customStyles={customStyles}
          conditionalRowStyles={conditionalRowStyles}
          fixedHeader={fixedHeader}
          keyField="pa2_id"
          striped
          responsive
          pagination
          paginationComponentOptions={paginationComponentOptions}
          paginationRowsPerPageOptions={[10, 20, 40, 100]}
          paginationPerPage={10}
          noDataComponent="Aucun résultat"
        />
      </>
    )
  );
};

export default Cr4Compo;
