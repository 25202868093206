import React from "react";
import HypCompo from "../components/HypCompo";
//import Cr2Compo from "../components/Cr2Compo";

const Home = () => {
  return (
    <div className="home">
      <div className="wrapper">
        <HypCompo />
        {/* <Cr2Compo /> */}
      </div>
    </div>
  );
};

export default Home;
