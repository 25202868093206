import axios from "axios";

export const GET_CHE = "GET_CHE";
export const GET_CHES = "GET_CHES";

export const getChes = () => {
  return (dispatch) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}api/s_che`)
      .then((res) => {
        dispatch({ type: GET_CHES, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const getCheById = (uid) => {
  return (dispatch) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}api/s_che/${uid}`)
      .then((res) => {
        dispatch({ type: GET_CHE, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};
