import {
  GET_RA2S,
  GET_RA2S_HYP,
  GET_RA2S_HYP_DATE,
  GET_RA2S_DATE,
} from "../actions/ra2.actions";

const initialState = {};

export default function ra2Reducer(state = initialState, action) {
  switch (action.type) {
    case GET_RA2S:
      return action.payload;
    case GET_RA2S_HYP:
      return action.payload;
    case GET_RA2S_HYP_DATE:
      return action.payload;
    case GET_RA2S_DATE:
      return action.payload;
    default:
      return state;
  }
}
