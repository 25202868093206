import { useState } from "react";

const NoticeHelp = () => {
  const [rapPopup, setRapPopup] = useState(false);

  const handleClickAide = () => {
    if (rapPopup === true) {
      let d1 = document.getElementById("d1");
      if (getComputedStyle(d1).display !== "none") {
        d1.style.display = "none";
      } else {
        d1.style.display = "block";
      }
      setRapPopup(false);
    } else {
      setRapPopup(true);
    }
  };

  return (
    
    <div className="centered">
      <button className="btn btn-success" onClick={() => handleClickAide()}>
        <h2>Aide</h2>
      </button>

      {rapPopup && (
        <div className="popup-profil-container">
          <div className="modal">
            <h3>Contenu + explication du site</h3>
            <span className="cross" onClick={() => setRapPopup(false)}>
              &#10005;
            </span>

            <h2>-------------------- B I E N V E N U E ---------------</h2>
            <pre>
              Ce site n'a aucun but lucratif, il peut vous donner une idée{" "}
              <span>purement indicative</span>
              <br></br>
              la façon de faire vos jeux selon des statistiques de toutes les
              courses PMU point de vente (France)<br></br>
              et tous les quintés depuis le 01/01/2022.
              <br></br>
              <br></br>
              <span>Attention:</span>Les cotes ne sont pas celles du PMU, elles
              sont approximatives.
              <br></br>
              -Se connecter vous permet un menu sélection plus large de
              statistiques<br></br>
              -Les gagnants du jour<br></br>
              -Les 1er favoris<br></br>
              -Les écarts Drivers et Entraineurs<br></br>
              -Les écarts Numéros<br></br>
              -Les chevaux gagnants supérieur à l'allocation
              <br></br>
              -Le menu sélection et tous les liens et entêtes (Noir et blanc)
              sont cliquables
              <br></br>
            </pre>
          </div>
        </div>
      )}
    </div>
  );
};
export default NoticeHelp;
