
import Routes from "./components/Routes";

const App = () => {
  return (

      <Routes />

  );
};

export default App;
