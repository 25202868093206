import axios from "axios";

export const GET_NOT = "GET_NOT";
export const GET_NOTS = "GET_NOTS";
export const GET_NOT_CHEID = "GET_NOT_CHEID";
export const ADD_NOT_CHEID = "ADD_NOT_CHEID";
export const PUT_NOT_CHEID = "PUT_NOT_CHEID";


export const getNotById = (id) => {
  return (dispatch) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}api/s_not/${id}`)
      .then((res) => {
        dispatch({ type: GET_NOT, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const getNots = () => {
  return (dispatch) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}api/s_not`)
      .then((res) => {
        dispatch({ type: GET_NOTS, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const getNotByCheId = (id) => {
  return (dispatch) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}api/s_not/che/${id}`)
      .then((res) => {
        dispatch({ type: GET_NOT_CHEID, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

// add
export const addNotByCheId = (notcheid, notpa2id, notnot) => {
  return (dispatch) => {
    return axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/s_not`,
      data: { notcheid, notpa2id, notnot },
    })
      .then((res) => {
        dispatch({ type: ADD_NOT_CHEID, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

// add
export const putNotByCheId = (notcheid, notnot) => {
  return (dispatch) => {
    return axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}api/s_not/che/${notcheid}`,
      data: { notcheid, notnot },
    })
      .then((res) => {
        dispatch({ type: PUT_NOT_CHEID, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

// put
/* export const putNotByCheId = (id) => {
  return (dispatch) => {
    return axios({
      method: "post",
      url: "/user/12345",
      data: {
        firstName: "Fred",
        lastName: "Flintstone",
      },
    });
  };
}; */
