import {
  GET_PA2,
  GET_PA2S,
  GET_PA2S_HYP,
  GET_PA2S_DATE,
  GET_PA2S_DRC,
  UPD_PA2_SEL_IN,
  UPD_PA2_SEL_OUT,
} from "../actions/pa2.actions";

const initialState = {};

export default function pa2Reducer(state = initialState, action) {
  switch (action.type) {
    case GET_PA2:
      return action.payload;
    case GET_PA2S:
      return action.payload;
    case GET_PA2S_HYP:
      return action.payload;
    case GET_PA2S_DATE:
      return action.payload;
    case GET_PA2S_DRC:
      return action.payload;
    case UPD_PA2_SEL_IN:
      return action.payload;
    case UPD_PA2_SEL_OUT:
      return action.payload;
    default:
      return state;
  }
}
