import { combineReducers } from 'redux';
import hypReducer from './hyp.reducer';
import cheReducer from './che.reducer';
import ra2Reducer from './ra2.reducer';
import pa2Reducer from './pa2.reducer';
import pacReducer from './pac.reducer';
import ra5Reducer from './ra5.reducer';
import notReducer from './not.reducer';

export default combineReducers({
  hypReducer, cheReducer, ra2Reducer, pa2Reducer, pacReducer, ra5Reducer, notReducer
});