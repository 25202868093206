import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../../pages/Home';
import Navbar from '../Navbar';

//site
import HypCompo from '../HypCompo'
import Ra5Compo from '../Ra5Compo'
import NoticeHelp from '../NoticeHelp';

const index = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        
        <Route path="/help" element={<NoticeHelp/>} exact/>
        <Route path="/hyps" element={<HypCompo/>} exact/>
        <Route path="/ra5s" element={<Ra5Compo/>} exact/>
        <Route path="/" element={<Home/>} exact/>
        
      </Routes>
    </Router>
  );
};

export default index;