import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import DataTable from "react-data-table-component";
import { tabChampLibRa5 } from "./Tableaux";
import { customStyles } from "./StylesDataTable";
import { getRa5s, delRa5ById } from "../actions/ra5.actions";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, dateJJMMAAAA } from "./Utils";
import differenceBy from "lodash/differenceBy";

const Ra5Compo = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const ra5 = useSelector((state) => state.ra5Reducer);
  const [items, setItems] = useState(ra5);
  const j = useRef(0);

  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  //Chargement tableaux
  const nameChamp = useMemo(() => tabChampLibRa5("nameChamp"), []);
  const libChampCourt = useMemo(() => tabChampLibRa5("libChampCourt"), []);
  const widChamp = useMemo(() => tabChampLibRa5("widChamp"), []);
  const tabSortable = useMemo(
    () => Array.from(nameChamp, () => true),
    [nameChamp]
  );

  useEffect(() => {
    if (isLoading) {
      dispatch(getRa5s());
      !isEmpty(ra5) && setIsLoading(false);
      setItems(ra5);
    }
  }, [dispatch, isLoading, ra5]);

  function handleFilter(event) {
    const newData = ra5.filter((rows) => {
      return rows.hyp_nom
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    setItems(newData);
  }

  const columns = Array.from({ length: nameChamp.length }, (v, i) => {
    const conditionalCellStyles = [
      {
        when: (row) =>
          (row.ra4_s1g > 0 && row.ra4_s1g <= 5 && nameChamp[i] === "ra4_s1g") ||
          (row.ra4_s1c > 0 && row.ra4_s1c <= 5 && nameChamp[i] === "ra4_s1c"),

        style: {
          backgroundColor: "#dfefdf",
          color: "blue",
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
      {
        when: (row) =>
          (row.ra4_s1g > 5 &&
            row.ra4_s1g <= 10 &&
            nameChamp[i] === "ra4_s1g") ||
          (row.ra4_s1c > 5 && row.ra4_s1c <= 10 && nameChamp[i] === "ra4_s1c"),
        style: {
          backgroundColor: "#9fcf9f",
          color: "blue",
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
      {
        when: (row) =>
          (row.ra4_s1g > 10 &&
            row.ra4_s1g <= 20 &&
            nameChamp[i] === "ra4_s1g") ||
          (row.ra4_s1c > 10 && row.ra4_s1c <= 20 && nameChamp[i] === "ra4_s1c"),
        style: {
          backgroundColor: "#5faf5f",
          color: "blue",
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
      {
        when: (row) =>
          (row.ra4_s1g > 20 && nameChamp[i] === "ra4_s1g") ||
          (row.ra4_s1c > 20 && nameChamp[i] === "ra4_s1c"),
        style: {
          backgroundColor: "#1f8f1f",
          color: "blue",
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
      {
        when: (row) =>
          (row.ra4_s1g <= -6 && nameChamp[i] === "ra4_s1g") ||
          (row.ra4_s1c <= -6 && nameChamp[i] === "ra4_s1c"),
        style: {
          backgroundColor: "red",
          color: "white",
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
      {
        when: (row) =>
          (row.ra4_s1g > -6 && row.ra4_s1g < 0 && nameChamp[i] === "ra4_s1g") ||
          (row.ra4_s1c > -6 && row.ra4_s1c < 0 && nameChamp[i] === "ra4_s1c"),
        style: {
          color: "red",
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
      {
        when: (row) =>
          row.ra4_nc2 !== row.ra4_nc3 && nameChamp[i] === "ra4_nc2",
        style: {
          backgroundColor: "yellow",
          color: "black",
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
      {
        when: (row) => row.ra4_typ === "z",
        style: {
          backgroundColor: "#00CED1",
          color: "black",
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
      {
        when: (row) =>
          (row.ra4_s1g < 0 &&
            nameChamp[i] === "ra4_s1g" &&
            row.ra4_typ === "z") ||
          (row.ra4_s1c < 0 &&
            nameChamp[i] === "ra4_s1c" &&
            row.ra4_typ === "z"),
        style: {
          backgroundColor: "black",
          color: "red",
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
      {
        when: (row) =>
          (row.ra4_s1g >= 0 &&
            nameChamp[i] === "ra4_s1g" &&
            row.ra4_typ === "z") ||
          (row.ra4_s1c >= 0 &&
            nameChamp[i] === "ra4_s1c" &&
            row.ra4_typ === "z"),
        style: {
          backgroundColor: "black",
          color: "white",
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    ];

    return {
      name: libChampCourt[i],
      selector: (row) => row[nameChamp[i]],
      conditionalCellStyles: conditionalCellStyles,

      cell: (row) => {
        if (nameChamp[i] === "ra4_dtm") {
          if (nameChamp[i] === "ra4_dtm") {
            return dateJJMMAAAA(row.ra4_dtm);
          }
        } else {
          return row[nameChamp[i]];
        }
      },

      sortable: tabSortable[i],
      width: widChamp[i],
    };
  });

  const paginationComponentOptions = {
    rowsPerPageText: "Lignes par page",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Tous",
    //paginationPerPage: 20,
    // paginationRowsPerPageOptions: [20, 30, 40, 50]
  };

  const fixedHeader = {
    fixedHeader: true,
    fixedHeaderScrollHeight: "100px",
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      j.current = 0;
      selectedRows.forEach((objet) => {
        console.log(objet.ra4_id + "id:" + j.current);
        if (
          window.confirm(
            `Choix : Sur de supprimer :\r ${selectedRows[j.current].hyp_nom}`
          )
        ) {
          setToggleCleared(!toggleCleared);
          setItems(differenceBy(items, selectedRows[j.current], "ra4_id"));
          dispatch(delRa5ById(selectedRows[j.current].ra4_id));
        }
        j.current++;
      });
      if (j.current > 0) {
        //if(!isLoading) {
        dispatch(getRa5s());
        setItems(ra5);
        setIsLoading(false);
        //}
      }
    };

    return (
      <button
        key="delete"
        onClick={handleDelete}
        style={{ backgroundColor: "red" }}
        icon
      >
        Delete
      </button>
    );
  }, [dispatch, items, ra5, selectedRows, toggleCleared]);

  return (
    <>
      {isLoading ? (
        "Loading..."
      ) : (
        <>
          <h3>
            <input type="text" onChange={handleFilter} />
          </h3>

          <DataTable
            title="Ra5"
            data={items}
            columns={columns}
            customStyles={customStyles}
            fixedHeader={fixedHeader}
            defaultSortField="ra4_typ"
            striped
            responsive
            pagination
            paginationComponentOptions={paginationComponentOptions}
            paginationRowsPerPageOptions={[20, 40, 80, 150]}
            paginationPerPage={20}
            keyField="ra4_id"
            selectableRows
            contextActions={contextActions}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}
          />
        </>
      )}
    </>
  );
};

export default Ra5Compo;
