import {
  GET_PA2S_CHE,
} from "../actions/pac.actions";

const initialState = {};

export default function pacReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PA2S_CHE:
      return action.payload;
    default:
      return state;
  }
}
