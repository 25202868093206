import axios from "axios";

export const GET_PA2S_CHE = "GET_PA2S_CHE";

export const getPa2sByChe = (id) => {
  return (dispatch) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}api/v_pa2/che/${id}`)
      .then((res) => {
        dispatch({ type: GET_PA2S_CHE, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};
