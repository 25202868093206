export const customStyles = {
  responsiveWrapper: {
		style: {},
	},
	header: {
		style: {
			fontSize: '22px',
			color: "white",
			backgroundColor: "green",
			minHeight: '40px',
			paddingLeft: '16px',
			paddingRight: '8px',
		},
	},
	subHeader: {
		style: {
			backgroundColor: "red",
			minHeight: '32px',
		},
  },
  head: {
		style: {
			color: "green",
			fontSize: '12px',
			fontWeight: 500,
		},
	},
	headRow: {
		style: {
      backgroundColor: "black",
      color: "white",
			minHeight: '32px',
			borderBottomWidth: '1px',
			borderBottomColor: "yellow",
			borderBottomStyle: 'solid',
		},
		denseStyle: {
			minHeight: '32px',
		},
	},
	/* headCells: {
		style: {
			paddingLeft: '16px',
			paddingRight: '1px',
		},
		draggingStyle: {
			cursor: 'move',
		},
	}, */
	headCells: {
    style: {
      fontSize: '12px',
      fontWeight: 'bold',
      paddingLeft: '10 2px',
      justifyContent: 'center',
			backgroundColor: 'black',
			color: 'white'
    },
  },
	contextMenu: {
		style: {
			backgroundColor: "blue",
			fontSize: '18px',
			fontWeight: 400,
			color: "black",
			paddingLeft: '16px',
			paddingRight: '8px',
			transform: 'translate3d(0, -100%, 0)',
			transitionDuration: '125ms',
			transitionTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)',
			willChange: 'transform',
		},
		activeStyle: {
			transform: 'translate3d(0, 0, 0)',
		},
	},
	cells: {
		style: {
			paddingLeft: '6px',
			paddingRight: '6px',
			//wordBreak: 'break-word',
		},
		draggingStyle: {},
	},
  rows: {
    style: {
      fontSize: '13px',
      fontWeight: 400,
      color: "black",
      backgroundColor: "white",
			minHeight: '28px',
			minWidth: 'auto',
      '&:not(:last-of-type)': {
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: "yellow",
      },
    },
    denseStyle: {
      minHeight: '32px',
    }
  }
};