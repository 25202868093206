import axios from "axios";

export const GET_PA2 = "GET_PA2";
export const GET_PA2S = "GET_PA2S";
export const GET_PA2S_HYP = "GET_PA2S_HYP";
export const GET_PA2S_DATE = "GET_PA2S_DATE";
export const GET_PA2S_DRC = "GET_PA2S_DRC";
export const UPD_PA2_SEL_IN = "UPD_PA2_SEL_IN";
export const UPD_PA2_SEL_OUT = "UPD_PA2_SEL_OUT";

export const getPa2ById = (id) => {
  return (dispatch) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}api/v_pa2/${id}`)
      .then((res) => {
        dispatch({ type: GET_PA2, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const getPa2s = () => {
  return (dispatch) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}api/v_pa2`)
      .then((res) => {
        dispatch({ type: GET_PA2S, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const getPa2sByHyp = (hyp) => {
  return (dispatch) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}api/v_pa2/hyp/${hyp}`)
      .then((res) => {
        dispatch({ type: GET_PA2S_HYP, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const getPa2sByDate = (dte) => {
  return (dispatch) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}api/v_pa2/date/${dte}`)
      .then((res) => {
        dispatch({ type: GET_PA2S_DATE, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const getPa2sByDrc = (pa2_drc) => {
  return (dispatch) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}api/v_pa2/drc/${pa2_drc}`)
      .then((res) => {
        dispatch({ type: GET_PA2S_DRC, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

/* export const updPa2Sel = (pa2Id) => {
  return (dispatch) => {
    return axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}api/s_pa2/sel/${pa2Id}`,
      data: { pa2_sel },
    })
      .then((res) => {
        dispatch({ type: UPD_PA2_SEL, payload: pa2_sel });
      })
      .catch((err) => console.log(err));
  };
}; */

export const updPa2SelIn = (id) => {
  return (dispatch) => {
    return axios
      .put(`${process.env.REACT_APP_API_URL}api/v_pa2/selin/${id}`)
      .then((res) => {
        dispatch({ type: UPD_PA2_SEL_IN, payload: { id } });
      })
      .catch((err) => console.log(err));
  };
};

export const updPa2SelOut = (id) => {
  return (dispatch) => {
    return axios
      .put(`${process.env.REACT_APP_API_URL}api/v_pa2/selout/${id}`)
      .then((res) => {
        dispatch({ type: UPD_PA2_SEL_OUT, payload: { id } });
      })
      .catch((err) => console.log(err));
  };
};

