import { NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { siUserMe } from "./Utils";

const NavBar = () => {
  const { user, isAuthenticated } = useAuth0();
  const { logout, loginWithRedirect } = useAuth0();

  return (
    <div className="centered">
      <div className="dark navbar">
        <ul className="left menu">
          <li className="item">
            <NavLink to="/">Accueil</NavLink>
          </li>

          <li className="item">
            <NavLink to="/help">Aide</NavLink>
          </li>

          {isAuthenticated && siUserMe(user) && (
            <>
              <li className="item">
                <NavLink to="/contact">Contact</NavLink>
              </li>
              <li className="hover dropdown">
                <span className="item">
                  <i className="caret"></i>Ra4/Ra5
                </span>
                <ul className="menu">
                  <li className="item">
                    <NavLink to="/ra4s">Ra4</NavLink>
                  </li>
                  <li className="item">
                    <NavLink to="/ra5s">Ra5</NavLink>
                  </li>
                </ul>
              </li>
            </>
          )}

          {!isAuthenticated ? (
            <li
              className="right menu sm:hidden small button"
              id="mobile-menu3"
              onClick={() => loginWithRedirect()}
            >
              <NavLink to="/">Se connecter</NavLink>
            </li>
          ) : (
            <li
              className="right menu sm:hidden small button"
              id="mobile-menu3"
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              <NavLink to="/">Se déconnecter</NavLink>
              <h3>{user.name}</h3>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default NavBar;
