import { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, dateJJMMAAAA, siUserMe, catAHP } from "./Utils";
import { tabMenuAdmin, tabMenuSelDbs } from "./Tableaux";
import {
  reqCr2par,
  reqCr2parSearch,
  reqCr2parSearchSuppLevel,
} from "./Requetes";

import { getHypsByDate } from "../actions/hyp.actions";
import { getRa2s, getRa2sByDate, getRa2sByHyp } from "../actions/ra2.actions";
import { getPa2s, getPa2sByDate, getPa2sByHyp } from "../actions/pa2.actions";
import Cr2Compo from "./Cr2Compo";
// import Ra5Compo from "./Ra5Compo";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import Cr3Compo from "./Cr3Compo";

import { useAuth0 } from "@auth0/auth0-react";

const HypCompo = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const hyps = useSelector((state) => state.hypReducer);
  const ra2s = useSelector((state) => state.ra2Reducer);
  const pa2s = useSelector((state) => state.pa2Reducer);

  const [itemsTrouve, setItemsTrouve] = useState([]);
  const [numTrouve, setNumTrouve] = useState([]);
  const [optMenuSelDbs, setOptMenuSelDbs] = useState(null);
  const [date, setDate] = useState(new Date());
  const [hypId, setHypId] = useState();
  const [hypNom, setHypNom] = useState();
  const { user, isAuthenticated } = useAuth0();

  registerLocale("fr", fr);
  setDefaultLocale("fr");

  //Chargement tableaux
  var menuSelDbs = tabMenuSelDbs();
  if (isAuthenticated && siUserMe(user)) {
    menuSelDbs = tabMenuAdmin(menuSelDbs);
  }

  useEffect(() => {
    const hypTousJour = () => {
      if (date !== undefined) {
        if (isLoading) {
          //let dateFor = dateYMD(date, 0);
          let dateFor = new Date(date).toISOString().substring(0, 10);
          dispatch(getHypsByDate(dateFor));
          //if (!isEmpty(hyps)) {
          dispatch(getRa2sByDate(dateFor));
          dispatch(getPa2sByDate(dateFor));
          if (isEmpty(pa2s)) {
          }
          //}
          setIsLoading(false);
        }
      }
    };
    hypTousJour();
  }, [date, dispatch, hyps, isLoading, pa2s, ra2s]);

  const handleClickHyp = (hypId, hypNom, fic) => {
    searchClear();
    if (fic === "j") {
      dispatch(getRa2sByHyp(hypId));
      dispatch(getPa2sByHyp(hypId));
    } else {
      dispatch(getRa2s());
      dispatch(getPa2s());
      setIsLoading(true);
    }
    setHypId(hypId);
    setHypNom(hypNom);
  };

  const handleClickDate = (date) => {
    searchClear();
    let dateFor = new Date(date).toISOString().substring(0, 10);
    dispatch(getHypsByDate(dateFor));
    dispatch(getRa2sByDate(dateFor));
    dispatch(getPa2sByDate(dateFor));
    setDate(dateFor);
  };

  const handleOptMenuSelDbsChange = (selectedOption) => {
    searchClear();
    let searchLib = selectedOption.label;
    setOptMenuSelDbs({ id: 1, value: "search", label: searchLib });
    let dateFor = new Date(date).toISOString().substring(0, 10);
    dispatch(getPa2sByDate(dateFor));
    // $$gn_requete partant ----------------
    let reqCr2detOut = [];
    reqCr2detOut = reqCr2par(pa2s, selectedOption);
    setItemsTrouve(reqCr2detOut[0]);
    setNumTrouve([...new Set(reqCr2detOut[1])]);
  };

  const handleSearchItems = (searchValue) => {
    let searchLib = "";
    if (!isEmpty(hypId)) {
      searchLib =
        "Votre recherche :" + searchValue + "-" + hypId + " " + hypNom;
    } else {
      searchLib = "Votre recherche :" + searchValue + "- Hippodromes du jour";
    }
    setOptMenuSelDbs({ id: 1, value: "search", label: searchLib });

    setNumTrouve([]);
    let cr2R = [];
    if (searchValue.length > 3) {
      let lastUpdate = Date.now();
      setTimeout(() => {
        const diff = Date.now() - lastUpdate;
        if (diff > 3000) {
          let drcNumTab = ["J", "E", "C"];
          for (let x = 0; x < drcNumTab.length; x++) {
            let reqCr2detOut = [];
            if (drcNumTab[x] === "J") {
              const cr2J = pa2s.filter((par) => {
                return par.joc_nom
                  .toLowerCase()
                  .includes(searchValue.toLowerCase());
              });
              reqCr2detOut = reqCr2parSearch(drcNumTab[x], cr2J);
              numTrouve.push(reqCr2detOut);
              cr2R.push(cr2J);
            }
            if (drcNumTab[x] === "E") {
              const cr2E = pa2s.filter((par) => {
                return par.ent_nom
                  .toLowerCase()
                  .includes(searchValue.toLowerCase());
              });
              reqCr2detOut = reqCr2parSearch(drcNumTab[x], cr2E);
              numTrouve.push(reqCr2detOut);
              cr2R.push(cr2E);
            }
            if (drcNumTab[x] === "C") {
              const cr2C = pa2s.filter((par) => {
                return par.che_nom
                  .toLowerCase()
                  .includes(searchValue.toLowerCase());
              });
              reqCr2detOut = reqCr2parSearch(drcNumTab[x], cr2C);
              numTrouve.push(reqCr2detOut);
              cr2R.push(cr2C);
            }
          }
          // $$gn_requete itemsTrouve -----------------
          let cr2RR = reqCr2parSearchSuppLevel(cr2R);
          //--------------------------------------

          setNumTrouve([...new Set(numTrouve)]);
          // suppression doublons avec ...new Set
          setItemsTrouve([...new Set(cr2RR)]);
        }
      }, 3000);
    } else {
      if (searchValue.length === 0) {
        setNumTrouve([]);
      }
    }
  };

  function searchClear() {
    var searchSaisie = document.getElementById("searchItems");
    if (searchSaisie.value !== "") {
      searchSaisie.value = "";
    }
    setItemsTrouve([]);
    setOptMenuSelDbs(null);
    setHypId('');
    setHypNom("Hippodromes du jour");
  }

  return (
    <>
      <div class="menuSelection">
        <table>
          <tr>
            <td width={50}></td>
            <td>
              <DatePicker
                showIcon
                showYearDropdown
                scrollableMonthYearDropdown
                dateFormat="dd-MM-yyyy"
                selected={date}
                onChange={(date) => handleClickDate(date)}
              />
            </td>
            <td width={10}></td>
            <td width="340">
              <input
                type="text"
                id="searchItems"
                placeholder="Jockey/Entraineur/chevaux- 3 caractères minimum"
                minLength="3"
                onChange={(e) => handleSearchItems(e.target.value)}
              />
            </td>
            <td>
              <button value="clear" onClick={(e) => searchClear()}>
                Effacer
              </button>{" "}
            </td>
            {isAuthenticated && (
              <>
                <td width="200">
                  <div>
                    <Select
                      options={menuSelDbs}
                      onChange={handleOptMenuSelDbsChange}
                      value=""
                      placeholder="Menu Sélection"
                      autoFocus={true}
                    />
                  </div>
                </td>
                <td>
                  {!isEmpty(hypId) ? (
                    <span>Hippodrome: {hypId}-{hypNom} </span>
                  ) : (
                    <span>Hippodromes du jour</span>
                  )}
                </td>
              </>
            )}
          </tr>
        </table>
      </div>

      {!isEmpty(hyps) && (
        <>
          <div className="trending-container">
            <div className="grid-item1">
              <ul>
                <li>
                  <button
                    onClick={() => {
                      handleClickHyp(300, "Tous les hippodromes", "t");
                    }}
                    className="btn btn-success"
                  >
                    TOUS
                  </button>
                </li>
                {hyps.map((hyp) => (
                  <li key={hyp.hyp_id}>
                    <button
                      onClick={() => {
                        handleClickHyp(hyp.hyp_id, hyp.hyp_nom, "j");
                      }}
                      className="btn btn-success"
                    >
                      {hyp.cr2_reu}-{hyp.hyp_nom}
                      <p>
                        {catAHP(hyp.cr2_cat)} {}
                        {!isEmpty(hyp.cr2_dtm) && dateJJMMAAAA(hyp.cr2_dtm)}
                      </p>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
{/* 
          <div>
            <Ra5Compo />
          </div> */}

          {!isEmpty(optMenuSelDbs) && !isEmpty(numTrouve) ? (
            <Cr3Compo cr3Exp={[itemsTrouve, numTrouve, optMenuSelDbs, pa2s]} />
          ) : !isEmpty(optMenuSelDbs) && isEmpty(numTrouve) ? (
            "Rien trouvé dans la séléction"
          ) : !isEmpty(ra2s) ? (
            <Cr2Compo ra2s={[ra2s, pa2s, hypId]} />
          ) : (
            "zzzzzzzzzzzzzzzzzzzzz"
          )}

          {/*           {isAuthenticated && siUserMe(user) && !isEmpty(hypId) && (
            <button
              className="btn btn-success"
              onClick={() => handleClickHyp(hypId, "h")}
            >
              <h2>+++</h2>
            </button>
          )} */}
        </>
      )}
    </>
  );
};

export default HypCompo;
